<template>
  <b-card>
    <div class="flex items-start">
      <b-button
        variant="primary"
        size="sm"
        class="mr-1 rounded-lg p-0"
        @click="$router.go(-1)"
      >
        <feather-icon
          size="2x"
          icon="ChevronLeftIcon"
        />
      </b-button>
      <div class="text-black mb-2 text-2xl font-semibold">
        Detail Pembayaran Fullfillment
      </div>
    </div>
    <div class="flex justify-between">
      <div class="bg-[#F8F8F8] px-[12px] py-[8px] rounded-lg">
        Tanggal Pembayaran : <span class="font-medium text-[#333]">{{ DAY_WITH_FULL_DATE(res.date_transaction) }}</span>
      </div>
      <div class="flex gap-6">
        <v-select
          v-model="printed"
          :options="printedOptions"
          :reduce="option => option.value"
          :clearable="false"
          placeholder="Semua"
          class="w-[250px]"
        />
        <v-select
          v-model="shipping"
          :options="shippingOptions"
          :reduce="option => option.value"
          :clearable="false"
          placeholder="Semua Ekspedisi"
          class="w-[250px]"
        />
      </div>
    </div>
    <b-table
      class="mt-6"
      :items="res.items"
      :fields="fields"
      :busy="loading"
      show-empty
      :empty-text="'Tidak ada data'"
    >
      <template #cell(order_date)="data">
        <div class="!min-w-[90px]">
          <div class="text-[#333333]">
            {{ DASH_DATE(data.value) }}
          </div>
          <div class="text-[#828282]">
            {{ TIME(data.value) }}
          </div>
        </div>
      </template>
      <template #cell(customer)="data">
        <div class="!min-w-[90px]">
          <div class="text-[#333333]">
            {{ data.value }}
          </div>
          <div class="text-[#333333]">
            <img
              :src="data.item.shipping_url"
              alt="shipping"
              class="!max-h-[20px] inline mr-[6px]"
            >
            <span class="text-[#828282]">{{ data.item.shipping_type }}</span>
          </div>
        </div>
      </template>
      <template #cell(products)="data">
        <div class="!max-w-[200px] space-y-6">
          <div
            v-for="(product, index) in data.value"
            :key="index"
          >
            <div class="flex gap-[8px] text-[#333333]">
              <img
                :src="product.thumbnail ? product.thumbnail : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                alt="product"
                class="max-w-[36px] max-h-[36px] rounded-full border"
              >
              <div class="w-full">
                <div class="flex !justify-between">
                  <span>{{ product.name }}</span>
                  <div class="text-[#333333] ml-6">
                    x{{ product.qty }}
                  </div>
                </div>
                <span class="text-[#828282]">{{ product.variant ? product.variant.name : '-' }}</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #cell(destination)="data">
        <div class="!max-w-[60px]">
          {{ data.value }}
        </div>
      </template>
      <template #cell(cnote)="data">
        <div class="!max-w-[60px]">
          <div
            class="text-[#333333] flex justify-center"
            style="line-break: anywhere;"
          >
            {{ data.value }}
          </div>
          <div
            v-if="data.item.is_printed"
            class="flex justify-center"
          >
            <div class="bg-[#DCF3EB] text-[#34A770] rounded-[12px] px-[14px] mt-[6px]">
              Tercetak
            </div>
          </div>
        </div>
      </template>
      <template #cell(warehouse)="data">
        <div class="!max-w-[100px]">
          <div class="flex gap-[8px] text-[#333333]">
            <img
              :src="data.item.warehouse_url ? data.item.warehouse_url : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
              alt="product"
              class="max-w-[36px] max-h-[36px] rounded-full border"
            >
            <span>{{ data.value }}</span>
          </div>
        </div>
      </template>
      <template #cell(fulfillment_fee)="data">
        <div class="text-center text-[#333333]">
          {{ data.value | rupiah }}
        </div>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { DASH_DATE, TIME, DAY_WITH_FULL_DATE } from '@/libs/formatDate'
import { newAxiosIns } from '@/libs/axios'
import vSelect from 'vue-select'
import filters from '@/libs/filters'
import { mapState } from 'vuex'

export default {
  components: {
    vSelect,
  },
  filters: { rupiah: filters.rupiah },
  data() {
    return {
      loading: false,
      res: {},
      fields: [
        {
          key: 'order_date', label: 'Tanggal Order', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8]', tdClass: 'align-top',
        },
        {
          key: 'customer', label: 'Pelanggan', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8]', tdClass: 'align-top',
        },
        {
          key: 'products', label: 'Produk', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8]', tdClass: 'align-top',
        },
        {
          key: 'destination', label: 'Alamat', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8]', tdClass: 'align-top text-[#333333]',
        },
        {
          key: 'cnote', label: 'Resi', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8] text-center', tdClass: 'align-top',
        },
        {
          key: 'warehouse', label: 'Mitra Gudang', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8]', tdClass: 'align-top',
        },
        {
          key: 'fulfillment_fee', label: 'Biaya Fulfillment', thClass: '!capitalize text-[#333333] !bg-[#F8F8F8] text-center', tdClass: 'align-top',
        },
      ],
      DAY_WITH_FULL_DATE,
      DASH_DATE,
      TIME,
      printed: '',
      printedOptions: [
        { label: 'Semua', value: '' },
        { label: 'Tercetak', value: 'printed' },
        { label: 'Belum tercetak', value: 'unprinted' },
      ],

      shipping: '',
      shippingOptions: [],
    }
  },
  computed: {
    ...mapState('filter', ['expedition']),
  },
  watch: {
    printed() {
      this.getDetail()
    },
    shipping() {
      this.getDetail()
    },
  },
  beforeMount() {
    this.getDetail()
    this.getExpeditions()
  },
  methods: {
    async getDetail() {
      this.loading = true
      const body = {
        order_ids: this.$route.query.ref_id,
        status_print: this.printed,
        shipping: this.shipping,
      }

      await newAxiosIns.post('/komship/api/v1/orders/transaction-detail', body)
        .then(res => {
          this.loading = false
          this.res = res.data.data
        })
        .catch(err => {
          this.loading = false
          console.error(err)
        })
    },
    async getExpeditions() {
      this.$store.dispatch('filter/getUpdateExpedition')
      this.shippingOptions = [
        { label: 'Semua Ekspedisi', value: '' },
        ...this.expedition.map(item => ({
          label: item.shipping_name,
          value: item.shipping_name,
        })),
      ]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
